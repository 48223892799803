<script setup lang="ts">
import { StandErrorToast, StandSuccessToast } from '@stpkg/stand-vue'
import { onMounted, ref, type ComponentPublicInstance } from 'vue'

import { useToasts } from '~/composables/useToasts'

const toasts = useToasts()

const autoClose = (el: Element | ComponentPublicInstance | null) => {
  if (el) {
    const instance = el as InstanceType<typeof StandSuccessToast>

    setTimeout(() => {
      instance.onRootClickButton?.()
    }, 3000)
  }
}

// 同時に :visible="true" でレンダリングされるとportalの都合上機能しないのでmountを待つ
const toastsVisible = ref(false)
onMounted(() => (toastsVisible.value = true))
</script>

<template>
  <div>
    <div id="stand-modal-base" />
    <div class="fixed inset-x-0 bottom-[-36px] z-10 mx-auto box-border h-auto w-[fit-content] max-w-360">
      <div
        id="stand-floating-base"
        class="flex flex-col gap-1"
      />
    </div>
    <template
      v-for="toast in toasts?.list()"
      :key="toast.id"
    >
      <StandSuccessToast
        v-if="toast.kind === 'success'"
        :ref="el => autoClose(el)"
        :visible="toastsVisible"
        @close="toasts?.remove(toast.id)"
      >
        {{ toast.message }}
      </StandSuccessToast>

      <StandErrorToast
        v-if="toast.kind === 'error'"
        :visible="toastsVisible"
        @close="toasts?.remove(toast.id)"
      >
        {{ toast.message }}
      </StandErrorToast>
    </template>
  </div>
</template>

<style scoped lang="scss">
/* 顧客一覧のモーダルのマークアップ的に、各モーダルにスタイルを当てて実現するのは難しかったのでここでスタイルを定義している
TODO: stand-ui-vue で modal-base を提供するなど検討したい */

#stand-modal-base {
  :deep(.stand-modal) {
    transition: transform 400ms cubic-bezier(0, 0, 0, 1);
  }

  @media screen and (min-width: 520px) {
    :deep(.stand-modal-bg:nth-last-child(n+2) .stand-modal) {
      transform: translateY(-28px) scale(0.95);
    }
    :deep(.stand-modal-bg:nth-last-child(n+3) .stand-modal) {
      transform: translateY(-56px) scale(0.9);
    }
  }

  :deep(.stand-modal-bg:not(:first-child)) {
    backdrop-filter: none;
    background-color: transparent;
  }
}
</style>
